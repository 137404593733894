.paperStyle {
    padding: 20px;
    height: 100%;
    width: 60%;
    margin: 20px auto;
    justify-items: flex-start;
}

@media all and (max-width: 670px) {
    .paperStyle {
        height: 100%;
        width: 75%;
    }
}