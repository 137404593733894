
.video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    background: url('../images/xcallylandingpage-01.svg') 
    center center/cover no-repeat;
    height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grade', 'Lucida Sans', Arial, sans-serif;
}

.text > h1 {
    color: #fff;
    font-size: 44px;
    padding-top: 250px;
}

.text > p {
    margin-top: 8px;
    color: #fff;
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grade', 'Lucida Sans', Arial, sans-serif;
    font-style: oblique;
}

.hero-btns {
    margin-top: 32px;
    /* color: #fff; */
}

.hero-btns .btn {
    margin: 6px;
    /* color: rgb(255, 0, 0); */
}

.fa-play-circle {
    margin-left: 4px;
}

.xcally-logo {
    position: absolute;
    align-self: flex-start;
    margin-top: 250px;
    /* margin-top: 700px; */
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }
    .hero-container > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .text > p {
        font-size: 16px;
        /* margin-top: -100px; */
    }
}

@media all and (max-width: 900px) {
    .xcally-logo {
        margin-top: 555px;
        margin-left: auto;
    }
}
@media all and (max-width: 900px) {
    .hero-container {
        margin-top: -100px;
        
    }
}