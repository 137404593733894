* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('./images/img-5.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(255, 217, 0);
  font-size: 100px;
}

.products {
  background-image: url('./images/img-home.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: rgba(12, 146, 0, 0.637);
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(255, 0, 0);
  font-size: 100px;
}

/* .App {
  text-align: center;
} */
